import React from 'react';
import {AnimatedOnScroll} from "react-animated-css-onscroll";
import './App.css';
import Clock from './Clock.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';
import LBB from './LBBlogo.png';
import LBBtiny from './LBBlogo-tiny.png';
import VeganLogo from './VeganLogo.png';
import SprayTanLogo from './SprayTanLogo.png';
import HairDressingIcon from './HairdressingIcon.png';
import InjectablesIcon from './InjectablesIcon.png';
import WaxingIcon from './WaxingIcon.png';
import MakeupIcon from './MakeupIcon.png';
import ThreadingIcon from './ThreadingIcon.png';
import HairScissorsIcon from './HairScissorsIcon.png';
import HairDyeingIcon from './HairDyeingIcon.png';
import BrowPic from './BrowPic.png';
import BrowPicR from './BrowPicR.png';
import {Animated} from "react-animated-css";
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import {db} from "./firebase.js";
import BridalIcon1 from './BridalIcon1.png';
import EventIcon from './EventIcon.png';

class BridalDeets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "BridalDeets",
      showHideBridalDeets: true,
      showHideToriMain: true,
      showHideTammyMain: true,
      showHideLoriMain: true,
      showHideLivMain: true,
      showHideToriDeets: false,
      showHideTammyDeets: false,
      showHideLoriDeets: false,
      showHideLivDeets: false,
      ShowHideBack: false,
      BridalTans: [],
      OtherServices: []
    };
    this.hideComponent = this.hideComponent.bind(this);
  }



  hideComponent(name) {
    console.log(name);
    switch (name) {
      case "Main":
        this.setState({ showHideToriMain: true,
                        showHideTammyMain: true,
                        showHideLoriMain: true,
                        showHideLivMain: true,
                        showHideToriDeets: false,
                        showHideTammyDeets: false,
                        showHideBridalDeets: false,
                        showHideLivDeets: false,
                        showHideBack: false });
        break;
      case "Tori":
        this.setState({ showHideToriMain: false,
                        showHideTammyMain: false,
                        showHideLoriMain: false,
                        showHideLivMain: false,
                        showHideToriDeets: true,
                        showHideTammyDeets: false,
                        showHideBridalDeets: false,
                        showHideLivDeets: false,
                        showHideBack: true });
        break;
      case "Tammy":
        this.setState({ showHideToriMain: false,
                        showHideTammyMain: false,
                        showHideLoriMain: false,
                        showHideLivMain: false,
                        showHideToriDeets: false,
                        showHideTammyDeets: true,
                        showHideBridalDeets: false,
                        showHideLivDeets: false,
                        showHideBack: true });
        break;
      case "Lori":
        this.setState({ showHideToriMain: false,
                        showHideTammyMain: false,
                        showHideLoriMain: false,
                        showHideLivMain: false,
                        showHideToriDeets: false,
                        showHideTammyDeets: false,
                        showHideBridalDeets: true,
                        showHideLivDeets: false,
                        showHideBack: true });
        break;
      case "Liv":
        this.setState({ showHideToriMain: false,
                        showHideTammyMain: false,
                        showHideLoriMain: false,
                        showHideLivMain: false,
                        showHideToriDeets: false,
                        showHideTammyDeets: false,
                        showHideBridalDeets: false,
                        showHideLivDeets: true,
                        showHideBack: true });
        break;
    }
  }

  componentDidMount() {

    window.scrollTo(0, 50);

    this.setState(this.props.state);

    db.collection("BridalTans").get().then((querySnapshot) => {
      const data = querySnapshot.docs.map(doc => doc.data());
      this.setState({BridalTans: data});
        querySnapshot.forEach((doc) => {
            console.log(`${doc.id} => ${doc.data()}`);
        });
    });


  }

  componentWillUnmount() {
  }

  render() {
    const { showHideBack, 
            showHideToriMain, 
            showHideTammyMain, 
            showHideLoriMain, 
            showHideLivMain, 
            showHideToriDeets, 
            showHideTammyDeets, 
            showHideBridalDeets, 
            showHideLivDeets,
            BridalTans,
            OtherServices } = this.state;
    
    return (

      <Container fluid>

      <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={this.props.showHideServices}>      
          
          <br/>
          <Row className="Social-Icons">
            <h2 className="TitleFont">Bridal & Event</h2>
          </Row>
          
        </Animated>

        <Row className="Spacer"/>
        <Row className="Spacer"/>
        <div>
          <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={this.props.showHideBridalDeets}>
                
                <Row className="Social-Icons">
                  <CardGroup className="FooterCenter">
                 

                  <div className="service-cont">
                  <Animated className="FooterCenter" animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={this.props.showHideToriMain}> 
                    
                    <Col>
                      <Card className="App-mainbg2">
                        <Row className="FooterCenter">
                          <Card.Img src={BridalIcon1} className="App-logo"/>
                        </Row>
                        <Card.Title style={{'font-size' : '10vmin', 'text-align':'center'}} className="text-black-moon">Bridal Spray Tans</Card.Title>
                        
                        <Card.Body>
                          <Card.Text className="text-black-gar">
                           
                          </Card.Text>
                          <Card.Img className="BefAftImg" src={"https://firebasestorage.googleapis.com/v0/b/lure-beauty-bar.appspot.com/o/ServicesPhotos%2FLivBridal.png?alt=media&token=1740a5b5-618e-4e31-a8c0-00931b8c65d5"}>
                                
                          </Card.Img>
                    
                          <Accordion className="service-card" eventKey="0">
                        <br/>
                  
                            {
                              BridalTans.map((service, index) =>
                                (<Accordion.Item  className="service-card">
                              <Accordion.Header className="btn-servicedesc" eventKey={JSON.stringify(index)}>
                                {service.name}
                              </Accordion.Header>
                              <Accordion.Body className="service-card">
                                
                                <Card.Text className="text-black-gar">
                                  {service.bridedesc}
                                </Card.Text>
                                <Card.Text className="text-black-gar">
                                  {service.maiddesc}
                                </Card.Text>
                              </Accordion.Body>
                            </Accordion.Item>))
                            }
                            
                          </Accordion>
                        </Card.Body>
                        <Row className="Carousel">
                        <Col>
                        <Card.Img style={{'height':'30vmax', 'width':'auto'}} src={"https://firebasestorage.googleapis.com/v0/b/lure-beauty-bar.appspot.com/o/ServicesPhotos%2FBridalParty1.png?alt=media&token=4dca3024-8f11-44d7-87dd-eb4bbce8382e"}>
                                
                          </Card.Img>
                          </Col>
                          <Col>
                          <Card.Img style={{'height':'30vmax', 'width':'auto'}} src={"https://firebasestorage.googleapis.com/v0/b/lure-beauty-bar.appspot.com/o/ServicesPhotos%2FBridalGroom.png?alt=media&token=f69e941b-8d9d-46a9-8130-09d3932b389e"}>
                                
                          </Card.Img>
                          </Col>
                          </Row>
                        </Card>
                    </Col>
                    
                  </Animated>
                  </div>
                    </CardGroup>
                  </Row>

<Row className="Spacer"/>
<Row className="Spacer"/>

                  
                
          </Animated>
          </div>
      </Container>
    );
  }
}

export default BridalDeets;