import React from 'react';
import {AnimatedOnScroll} from "react-animated-css-onscroll";
import './App.css';
import Clock from './Clock.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';
import LBB from './LBBlogo.png';
import LBBtiny from './LBBlogo-tiny.png';
import SKlogo from './SKlogo.png';
import DMlogo from './DMlogo.png';
import BMlogo from './blondme-logo.png';
import BClogo from './bonacure-logo.png';
import OSISlogo from './osis-logo.png';
import VeganLogo from './VeganLogo.png';
import HaircutIcon from './HaircutIcon.png';
import HairstyleIcon from './HairstyleIcon.png';
import HairDressingIcon from './HairdressingIcon.png';
import InjectablesIcon from './InjectablesIcon.png';
import WaxingIcon from './WaxingIcon.png';
import MakeupIcon from './MakeupIcon.png';
import ThreadingIcon from './ThreadingIcon.png';
import HairScissorsIcon from './HairScissorsIcon.png';
import HairDyeingIcon from './HairDyeingIcon.png';
import BrowPic from './BrowPic.png';
import BrowPicR from './BrowPicR.png';
import {Animated} from "react-animated-css";
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import {db} from "./firebase.js";
import TammyCut1 from './TammyCut1.jpg';
import TammyStyle1 from './TammyStyle1.jpg';
import TammyServicesPic from './TammyAboutPic.jpeg';


class HairProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "HairProducts",
      showHideHairProducts: true,
      showHideToriMain: true,
      showHideTammyMain: true,
      showHideLoriMain: true,
      showHideLivMain: true,
      showHideToriDeets: false,
      showHideHairProducts: false,
      showHideLoriDeets: false,
      showHideLivDeets: false,
      ShowHideBack: false,
      Haircuts: [],
      HairStyles: []
    };
    this.hideComponent = this.hideComponent.bind(this);
  }



  hideComponent(name) {
    console.log(name);
    switch (name) {
      case "Main":
        this.setState({ showHideToriMain: true,
                        showHideTammyMain: true,
                        showHideLoriMain: true,
                        showHideLivMain: true,
                        showHideToriDeets: false,
                        showHideHairProducts: false,
                        showHideLoriDeets: false,
                        showHideLivDeets: false,
                        showHideBack: false });
        break;
      case "Tori":
        this.setState({ showHideToriMain: false,
                        showHideTammyMain: false,
                        showHideLoriMain: false,
                        showHideLivMain: false,
                        showHideToriDeets: true,
                        showHideHairProducts: false,
                        showHideLoriDeets: false,
                        showHideLivDeets: false,
                        showHideBack: true });
        break;
      case "Tammy":
        this.setState({ showHideToriMain: false,
                        showHideTammyMain: false,
                        showHideLoriMain: false,
                        showHideLivMain: false,
                        showHideToriDeets: false,
                        showHideHairProducts: true,
                        showHideLoriDeets: false,
                        showHideLivDeets: false,
                        showHideBack: true });
        break;
      case "Lori":
        this.setState({ showHideToriMain: false,
                        showHideTammyMain: false,
                        showHideLoriMain: false,
                        showHideLivMain: false,
                        showHideToriDeets: false,
                        showHideHairProducts: false,
                        showHideLoriDeets: true,
                        showHideLivDeets: false,
                        showHideBack: true });
        break;
      case "Liv":
        this.setState({ showHideToriMain: false,
                        showHideTammyMain: false,
                        showHideLoriMain: false,
                        showHideLivMain: false,
                        showHideToriDeets: false,
                        showHideHairProducts: false,
                        showHideLoriDeets: false,
                        showHideLivDeets: true,
                        showHideBack: true });
        break;
    }
  }

  componentDidMount() {

    window.scrollTo(0, 50);

    this.setState(this.props.state);

    db.collection("TammyCuts").get().then((querySnapshot) => {
      const data = querySnapshot.docs.map(doc => doc.data());
      this.setState({Haircuts: data});
        querySnapshot.forEach((doc) => {
            console.log(`${doc.id} => ${doc.data()}`);
        });
    });

    db.collection("TammyStyles").get().then((querySnapshot) => {
      const data = querySnapshot.docs.map(doc => doc.data());
      this.setState({HairStyles: data});
        querySnapshot.forEach((doc) => {
            console.log(`${doc.id} => ${doc.data()}`);
        });
    });

  }

  componentWillUnmount() {
  }

  render() {
    const { showHideBack, 
            showHideToriMain, 
            showHideTammyMain, 
            showHideLoriMain, 
            showHideLivMain, 
            showHideToriDeets, 
            showHideHairProducts, 
            showHideLoriDeets, 
            showHideLivDeets,
            Haircuts,
            HairStyles } = this.state;
    
    return (

      <Container fluid>

      <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={this.props.showHideServices}>      
          
          <Row className="Social-Icons">
            <h3 className="TitleFont">Hair</h3>
          </Row>
        </Animated>

        <Row className="Spacer"/>
        <Row className="Spacer"/>
        <div>
          <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={this.props.showHideBodyProducts}>
                
                <Row className="Social-Icons">
                <CardGroup className="FooterCenter">
                  
                 

                <div className="service-cont">
                  <Animated className="FooterCenter" animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={this.props.showHideToriMain}> 
                      <Col lg={6} md={12} sm={12}>
                      <Card className="App-mainbg2">
                      <Row className="FooterCenter">
                          <Card.Img src={SKlogo} style={{'height': '20vmin', 'width':'50vmin'}}/>
                        </Row>
                        <br/>
                        <Card.Title style={{'fontSize' : '5vmax', 'textAlign':'center'}} className="text-black-moon">Schwarzkopf</Card.Title>
                        
                        <Card.Body>
                          <Card.Text className="text-black-gar">
                            A brand of firsts, Schwarzkopf’s rich history is defined by innovations. The first dry shampoo, the first liquid shampoo, the first hairspray, the first cold perm, the first home colour product. Over 120 years later and the brand is still striving to improve the haircare experience with every new product they create. Leading where others follow and encouraging people to showcase the best version of themselves, they proudly provide the tools needed for everyone to embrace and celebrate their individuality.
                          </Card.Text>
                          <Row className="FooterCenter">
                            <Card.Img src={BMlogo} style={{'height': '30vmin', 'width':'30vmin'}}/>
                            <Card.Img src={OSISlogo} style={{'height': '30vmin', 'width':'30vmin'}}/>
                            <Card.Img src={BClogo} style={{'height': '30vmin', 'width':'30vmin'}}/>
                          </Row>
                        </Card.Body>
                        <Card.Body>
                        
                        </Card.Body>
                        </Card>
                      </Col>

                    <Col lg={6} md={12} sm={12}>
                      <Card className="App-mainbg2">
                      <br/>
                        <br/>
                        <Row className="FooterCenter">
                          <Card.Img src={DMlogo} style={{ 'width':'70vmin'}}/>
                        </Row>
                        
                        <br/>
                        <br/>
                        <Card.Title style={{'font-size' : '5vmax', 'text-align':'center'}} className="text-black-moon">Design.ME</Card.Title>
                        
                        <Card.Body>
                          <Card.Text className="text-black-gar">
                            Having great hair is nice, but feeling great is even better!

Design.ME's goal is to make you feel like the best version of yourself. To achieve this, they strive to create hair products that awaken imagination and nurture individuality.

A proudly Canadian brand, all of their products are made with love in Montreal, Quebec. Through carefully crafted proprietary formulas, they create salon-quality, innovative hair products that promote confidence and inspire you to design your own beauty.
                          </Card.Text>
                          
                          
                        </Card.Body>
                        </Card>
                      </Col>
                  </Animated>
                  </div>
                  </CardGroup>  
                  </Row>
<Row className="Spacer"/>
<Row className="Spacer"/>
               
                
          </Animated>
        </div>
      </Container>
    );
  }
}

export default HairProducts;