import React from 'react';
import {AnimatedOnScroll} from "react-animated-css-onscroll";
import logo from './logo.svg';
import './App.css';
import Clock from './Clock.js';
import AboutUs from './AboutUs.js';
import Contact from './Contact.js';
import Services from './Services.js';
import Products from './Products.js';
import Main from './Main.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';
import LBB from './LBBlogo.png';
import LURE from './Lure.PNG';
import bolhuisMC from './bolhuisMCLure.PNG';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import FB from './FB-glyph2.png';
import FB2 from './FindUsOn_Header_2019.png';
import IG from './Glyph-Icon-IG2.png';
import IG2 from './IG-logo-black-and-white-transparent.png';
import CALL from './Call-Glyph2.png';
import EM from './Email-Glyph2.png';
import {Animated} from "react-animated-css";
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { BrowserRouter, Route, Link, Switch } from "react-router-dom";

class App extends React.Component {

  constructor() {
    super();
    this.state = {
      name: "React",
      showHideHome: true,
      showHideAboutUs: false,
      showHideContact: false,
      showHideServices: false,
      showHideProducts: false,
      HomeActive: true,
      AboutUsActive: false,
      ServicesActive: false,
      ProductsActive: false,
      ContactActive: false
    };
    this.hideComponent = this.hideComponent.bind(this);
  }

  hideComponent(name) {
    console.log(name);
    switch (name) {
      case "Home":
        this.setState({ showHideHome: true,
                        showHideAboutUs: false,
                        showHideContact: false,
                        showHideServices: false,
                        showHideProducts: false,
                        HomeActive: true,
                        AboutUsActive: false,
                        ServicesActive: false,
                        ProductsActive: false,
                        ContactActive: false });
        break;
      case "AboutUs":
        this.setState({ showHideAboutUs: true,
                        showHideHome: false,
                        showHideContact: false,
                        showHideServices: false,
                        showHideProducts: false,
                        HomeActive: false,
                        AboutUsActive: true,
                        ServicesActive: false,
                        ProductsActive: false,
                        ContactActive: false });
        break;
      case "Contact":
        this.setState({ showHideContact: true,
                        showHideHome: false,
                        showHideAboutUs: false,
                        showHideServices: false,
                        showHideProducts: false,
                        HomeActive: false,
                        AboutUsActive: false,
                        ServicesActive: false,
                        ProductsActive: false,
                        ContactActive: true });
        break;
      case "Services":
        this.setState({ showHideContact: false,
                        showHideHome: false,
                        showHideAboutUs: false,
                        showHideServices: true,
                        showHideProducts: false,
                        HomeActive: false,
                        AboutUsActive: false,
                        ServicesActive: true,
                        ProductsActive: false,
                        ContactActive: false });
        break;
      case "Products":
        this.setState({ showHideContact: false,
                        showHideHome: false,
                        showHideAboutUs: false,
                        showHideServices: false,
                        showHideProducts: true,
                        HomeActive: false,
                        AboutUsActive: false,
                        ServicesActive: false,
                        ProductsActive: true,
                        ContactActive: false });
        break;
    }
  }

  setExpanded(expanded){

    this.setState({expanded: expanded});
   
  }

  closeNav(){

    this.setState({expanded: false});
   
  }

  componentDidMount() {
    const ele = document.getElementById('ipl-progress-indicator')
      if(ele){
        // fade out
        ele.classList.add('available')
        setTimeout(() => {
          // remove from DOM
          ele.outerHTML = ''
        }, 5000)
      }
  }



  render(){

    const { showHideHome, showHideAboutUs, showHideContact, showHideServices, showHideProducts, HomeActive, AboutUsActive, ServicesActive, ProductsActive, ContactActive, expanded } = this.state;
    return (
      <BrowserRouter>
      <div className="App-mainbg">
    <Container fluid className="App-mainbg2">

    <Navbar className="FooterRight" fixed="top">
      <ButtonGroup className="FooterRight2">
                <Link to="/"><Button variant="link" active={HomeActive} className="btn-primary" onClick={() => this.hideComponent("Home")}>Home</Button></Link>
              
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 250 }}
                overlay={<Tooltip id="button-tooltip">
                            Meet our specialists & what they offer!
                          </Tooltip>}
              >
                <Link to="/team"><Button variant="link" active={AboutUsActive} className="btn-primary" onClick={() => this.hideComponent("AboutUs")}>About</Button></Link>
              </OverlayTrigger>

              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 250 }}
                overlay={<Tooltip id="button-tooltip">
                            Get the deets on our services
                          </Tooltip>}
              >
                <Link to="/services"><Button variant="link" active={ServicesActive} className="btn-primary" onClick={() => this.hideComponent("Services")}>Services</Button></Link>
              </OverlayTrigger>

              

              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 250 }}
                overlay={<Tooltip id="button-tooltip">
                            Find us or give us a shout!
                          </Tooltip>}
              >
                <Link to="/contact"><Button variant="link" active={ContactActive} className="btn-primary" onClick={() => this.hideComponent("Contact")}>Contact</Button></Link>
              </OverlayTrigger>

              
            </ButtonGroup>
    </Navbar>
      <Row className="Spacer"/>
      <Row className="Spacer"/>
      <Row className="Spacer"/>
      <Row className="Spacer"/>
      <Row className="Spacer"/>
      <Row className="Spacer"/>
      <Row className="Spacer"/>
      <Switch className="App">
              
              <Route path="/contact" component={Contact}/>
              <Route path="/team" component={AboutUs}/>
              <Route path="/services" component={Services}/>
              <Route exact path="/" component={Main}/>

      </Switch> 
      
      <Row className="Spacer"/>
      <Row className="Spacer"/>
      <Row>
        <Col xs={5} className="FooterLeft">
          <Row className="FooterLeft">
              <Button target="_blank" href="https://www.instagram.com/beautybylure/" variant="link" className="btn-tertiary" ><Image className='image-footer' src={IG}/></Button>
              <Button target= "_blank" href="https://www.facebook.com/beautybylure/" variant="link" className="btn-tertiary"><Image className='image-footer' src={FB}/></Button>
              <Button variant="link" href="mailto: contact@beautybylure.com" className="btn-tertiary"><Image className='image-footer' src={EM}/></Button>
            
          </Row>
        </Col>
        <Col xs={2} className="FooterCenter">
            <Row className="Social-Icons">
              <Image src={LBB}  className="LBBLogo" roundedCircle/>

            </Row>
        </Col>
        <Col xs={5} className="FooterRight">
          <Row noGutters={true} className="FooterRight">
            <Col xs={12} className="HoursNav">
              <h4 className="text-white-moon-pad">Created By </h4>

              <Image src={bolhuisMC} className="LBBLogo" style={{'height':'7vmax', 'width':'7vmax'}} roundedCircle/>
            </Col>

          </Row>
        </Col>

      </Row>
      <br/>
    </Container>
    </div>
    </BrowserRouter>
    );
  }
}

export default App;
