import React from 'react';
import {AnimatedOnScroll} from "react-animated-css-onscroll";
import './App.css';
import Clock from './Clock.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';
import LBB from './LBBlogo.png';
import LBBtiny from './LBBlogo-tiny.png';
import NUDALogo from './nuda-canada-logo.png';
import BushBalmLogo from './Bushbalm-logo.png';
import HairDressingIcon from './HairdressingIcon.png';
import InjectablesIcon from './InjectablesIcon.png';
import WaxingIcon from './WaxingIcon.png';
import WaxingIcon2 from './WaxingIcon2.png';
import WaxingIcon3 from './WaxingIcon3.png';
import WaxingIcon4 from './WaxingIcon4.png';
import MakeupIcon from './MakeupIcon.png';
import ThreadingIcon from './ThreadingIcon.png';
import HairScissorsIcon from './HairScissorsIcon.png';
import HairDyeingIcon from './HairDyeingIcon.png';
import BrowPic from './BrowPic.png';
import BrowPicR from './BrowPicR.png';
import {Animated} from "react-animated-css";
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import {db} from "./firebase.js";
import ToriBrowWax1 from './ToriBrowWax1.jpg';
import ToriSprayTan1 from './ToriSprayTan1.jpg';
import ToriLegWax1 from './ToriLegWax1.jpg';
import ToriServicesPic from './ToriAboutPic.jpeg';



class BodyProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "BodyProducts",
      showHideBodyProducts: true,
      showHideToriMain: true,
      showHideTammyMain: true,
      showHideLoriMain: true,
      showHideLivMain: true,
      showHideBodyProducts: false,
      showHideTammyDeets: false,
      showHideLoriDeets: false,
      showHideLivDeets: false,
      ShowHideBack: false,
      NUDA: [],
      BushBalm: []
    };
    this.hideComponent = this.hideComponent.bind(this);
  }



  hideComponent(name) {
    console.log(name);
    switch (name) {
      case "Main":
        this.setState({ showHideToriMain: true,
                        showHideTammyMain: true,
                        showHideLoriMain: true,
                        showHideLivMain: true,
                        showHideBodyProducts: false,
                        showHideTammyDeets: false,
                        showHideLoriDeets: false,
                        showHideLivDeets: false,
                        showHideBack: false });
        break;
      case "Tori":
        this.setState({ showHideToriMain: false,
                        showHideTammyMain: false,
                        showHideLoriMain: false,
                        showHideLivMain: false,
                        showHideBodyProducts: true,
                        showHideTammyDeets: false,
                        showHideLoriDeets: false,
                        showHideLivDeets: false,
                        showHideBack: true });
        break;
      case "Tammy":
        this.setState({ showHideToriMain: false,
                        showHideTammyMain: false,
                        showHideLoriMain: false,
                        showHideLivMain: false,
                        showHideBodyProducts: false,
                        showHideTammyDeets: true,
                        showHideLoriDeets: false,
                        showHideLivDeets: false,
                        showHideBack: true });
        break;
      case "Lori":
        this.setState({ showHideToriMain: false,
                        showHideTammyMain: false,
                        showHideLoriMain: false,
                        showHideLivMain: false,
                        showHideBodyProducts: false,
                        showHideTammyDeets: false,
                        showHideLoriDeets: true,
                        showHideLivDeets: false,
                        showHideBack: true });
        break;
      case "Liv":
        this.setState({ showHideToriMain: false,
                        showHideTammyMain: false,
                        showHideLoriMain: false,
                        showHideLivMain: false,
                        showHideBodyProducts: false,
                        showHideTammyDeets: false,
                        showHideLoriDeets: false,
                        showHideLivDeets: true,
                        showHideBack: true });
        break;
    }
  }

  componentDidMount() {

    window.scrollTo(0, 50);

    this.setState(this.props.state);

    db.collection("NUDAProducts").get().then((querySnapshot) => {
      const data = querySnapshot.docs.map(doc => doc.data());
      this.setState({NUDA: data});
        querySnapshot.forEach((doc) => {
            console.log(`${doc.id} => ${doc.data()}`);
        });
    });

    db.collection("BushBalmProducts").get().then((querySnapshot) => {
      const data = querySnapshot.docs.map(doc => doc.data());
      this.setState({BushBalm: data});
        querySnapshot.forEach((doc) => {
            console.log(`${doc.id} => ${doc.data()}`);
        });
    });

  }

  componentWillUnmount() {
  }

  render() {
    const { showHideBack, 
            showHideToriMain, 
            showHideTammyMain, 
            showHideLoriMain, 
            showHideLivMain, 
            showHideBodyProducts, 
            showHideTammyDeets, 
            showHideLoriDeets, 
            showHideLivDeets,
            NUDA,
            BushBalm
            } = this.state;
    
    return (

      <Container fluid>

      <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={this.props.showHideServices}>      
          
          <Row className="Social-Icons">
            <h3 className="TitleFont">Body</h3>
          </Row>
        </Animated>

        <Row className="Spacer"/>
        <Row className="Spacer"/>
        <div>
          <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={this.props.showHideBodyProducts}>
                
                <Row className="Social-Icons">
                <CardGroup className="FooterCenter">
                  
                 

                <div className="service-cont">
                  <Animated className="FooterCenter" animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={this.props.showHideToriMain}> 
                      <Col lg={6} sm={12} className="Social-Icons2">
                      <Card className="App-mainbg2">
                      <Row className="FooterCenter">
                          <Card.Img src={NUDALogo} style={{'height': '10vmax', 'width':'25vmax'}}/>
                        </Row>
                        <br/>
                        <Card.Title style={{'fontSize' : '10vmin', 'textAlign':'center'}} className="text-black-moon">NUDA</Card.Title>
                        
                        <Card.Body>
                          <Card.Text className="text-black-gar">
                            NUDA is a Canadian-based business founded by two passionate entrepreneurs that offers high-quality skincare and spray tanning products. NUDA products are made from natural, handpicked ingredients designated to take care of your skin. All the products are vegan, cruelty-free, made without paraben nor gluten. The NUDA skincare range offers amazing skincare products designed for people who want to maintain an healthy, flawless and glow skin at home.
                          </Card.Text>
                        
                          <Accordion className="service-card">
                            
                  
                            {
                              NUDA.map((product, index) =>
                                (<Accordion.Item className="service-card" eventKey={JSON.stringify(index)}>
                              <Accordion.Header className="btn-servicedesc" style={{'paddingBottom':'5px'}}>
                                {product.name}<Image className="image-padding" src={LBBtiny}/>${product.price} 
                              </Accordion.Header>
                              <Accordion.Body className="service-card">
                                <Image src={product.imageurl} style={{'width':'15vmax', 'paddingBottom':'15px'}} roundedCircle/>
                                
                                <Card.Text className="text-black-gar">
                                  {product.description}
                                </Card.Text>
                              </Accordion.Body>
                            </Accordion.Item>))
                            }
                          </Accordion>
                        </Card.Body>
                        <Card.Body>
                        
                        </Card.Body>
                        </Card>
                      </Col>

                    <Col lg={6} sm={12} className="Social-Icons2">
                      <Card className="App-mainbg2">
                      <br/>
                        <br/>
                        <Row className="FooterCenter">
                          <Card.Img src={BushBalmLogo} style={{'width':'20vmax'}}/>
                        </Row>
                        
                        <br/>
                        <br/>
                        <Card.Title style={{'font-size' : '10vmin', 'text-align':'center'}} className="text-black-moon">Bushbalm™</Card.Title>
                        
                        <Card.Body>
                          <Card.Text className="text-black-gar">
                            Bushbalm™ is an evolving natural skincare product line - specifically, but not limited to, targeting areas that are commonly ignored. Think bikini line, underarms, legs, tush, or anywhere else on the body that deserves extra care. Their products were created to take care of your most sensitive areas while making sure you feel confident in your skin down there and everywhere with results that you will see and feel.
                          </Card.Text>
                          <Accordion className="service-card" eventKey="0">
                            {
                              BushBalm.map((product, index) =>
                                (<Accordion.Item className="service-card" eventKey={JSON.stringify(index)}>
                              <Accordion.Header className="btn-servicedesc" style={{'paddingBottom':'5px'}}>
                                {product.name}<Image className="image-padding" src={LBBtiny}/>${product.price} 
                              </Accordion.Header>
                              <Accordion.Body className="service-card">
                                <Image src={product.imageurl} style={{'width':'25vmax', 'paddingBottom':'15px'}} roundedCircle/>
                                
                                <Card.Text className="text-black-gar">
                                  {product.description}
                                </Card.Text>
                              </Accordion.Body>
                            </Accordion.Item>))
                            }
                          </Accordion>
                          
                        </Card.Body>
                        </Card>
                      </Col>
                  </Animated>
                  </div>
                  </CardGroup>  
                  </Row>

<Row className="Spacer"/>
<Row className="Spacer"/>
                
          </Animated>
        </div>
      </Container>
    );
  }
}

export default BodyProducts;