import React from 'react';
import {AnimatedOnScroll} from "react-animated-css-onscroll";
import './App.css';
import Clock from './Clock.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';
import LBB from './LBBlogo.png';
import LBBtiny from './LBBlogo-tiny.png';
import VeganLogo from './VeganLogo.png';
import HaircutIcon from './HaircutIcon2.png';
import HairstyleIcon from './HairstyleIcon2.png';
import InjectablesIcon from './InjectablesIcon.png';
import WaxingIcon from './WaxingIcon.png';
import MakeupIcon from './MakeupIcon.png';
import ThreadingIcon from './ThreadingIcon.png';
import HairScissorsIcon from './HairScissorsIcon.png';
import HairDyeingIcon from './HairDyeingIcon.png';
import BrowPic from './BrowPic.png';
import BrowPicR from './BrowPicR.png';
import {Animated} from "react-animated-css";
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import {db} from "./firebase.js";
import TammyCut1 from './TammyCut1.jpg';
import TammyStyle1 from './TammyStyle1.jpg';
import TammyServicesPic from './TammyAboutPic.jpeg';


class TammyDeets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "TammyDeets",
      showHideTammyDeets: true,
      showHideToriMain: true,
      showHideTammyMain: true,
      showHideLoriMain: true,
      showHideLivMain: true,
      showHideToriDeets: false,
      showHideTammyDeets: false,
      showHideLoriDeets: false,
      showHideLivDeets: false,
      ShowHideBack: false,
      Haircuts: [],
      HairStyles: []
    };
    this.hideComponent = this.hideComponent.bind(this);
  }



  hideComponent(name) {
    console.log(name);
    switch (name) {
      case "Main":
        this.setState({ showHideToriMain: true,
                        showHideTammyMain: true,
                        showHideLoriMain: true,
                        showHideLivMain: true,
                        showHideToriDeets: false,
                        showHideTammyDeets: false,
                        showHideLoriDeets: false,
                        showHideLivDeets: false,
                        showHideBack: false });
        break;
      case "Tori":
        this.setState({ showHideToriMain: false,
                        showHideTammyMain: false,
                        showHideLoriMain: false,
                        showHideLivMain: false,
                        showHideToriDeets: true,
                        showHideTammyDeets: false,
                        showHideLoriDeets: false,
                        showHideLivDeets: false,
                        showHideBack: true });
        break;
      case "Tammy":
        this.setState({ showHideToriMain: false,
                        showHideTammyMain: false,
                        showHideLoriMain: false,
                        showHideLivMain: false,
                        showHideToriDeets: false,
                        showHideTammyDeets: true,
                        showHideLoriDeets: false,
                        showHideLivDeets: false,
                        showHideBack: true });
        break;
      case "Lori":
        this.setState({ showHideToriMain: false,
                        showHideTammyMain: false,
                        showHideLoriMain: false,
                        showHideLivMain: false,
                        showHideToriDeets: false,
                        showHideTammyDeets: false,
                        showHideLoriDeets: true,
                        showHideLivDeets: false,
                        showHideBack: true });
        break;
      case "Liv":
        this.setState({ showHideToriMain: false,
                        showHideTammyMain: false,
                        showHideLoriMain: false,
                        showHideLivMain: false,
                        showHideToriDeets: false,
                        showHideTammyDeets: false,
                        showHideLoriDeets: false,
                        showHideLivDeets: true,
                        showHideBack: true });
        break;
    }
  }

  componentDidMount() {

    window.scrollTo(0, 50);

    this.setState(this.props.state);

    db.collection("TammyCuts").get().then((querySnapshot) => {
      const data = querySnapshot.docs.map(doc => doc.data());
      this.setState({Haircuts: data});
        querySnapshot.forEach((doc) => {
            console.log(`${doc.id} => ${doc.data()}`);
        });
    });

    db.collection("TammyStyles").get().then((querySnapshot) => {
      const data = querySnapshot.docs.map(doc => doc.data());
      this.setState({HairStyles: data});
        querySnapshot.forEach((doc) => {
            console.log(`${doc.id} => ${doc.data()}`);
        });
    });

  }

  componentWillUnmount() {
  }

  render() {
    const { showHideBack, 
            showHideToriMain, 
            showHideTammyMain, 
            showHideLoriMain, 
            showHideLivMain, 
            showHideToriDeets, 
            showHideTammyDeets, 
            showHideLoriDeets, 
            showHideLivDeets,
            Haircuts,
            HairStyles } = this.state;
    
    return (

      <Container fluid>

      <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={this.props.showHideServices}>      
          <Row className="Social-Icons">
            <Image className="FaceIcon" src={"https://firebasestorage.googleapis.com/v0/b/lure-beauty-bar.appspot.com/o/ProfilePhotos%2FTammyAboutPic.jpeg?alt=media&token=3a6f8df3-da8f-4359-a637-84c533a1f47d"} roundedCircle/>
          </Row>
          <br/>
          <Row className="Social-Icons">
            <h2 className="TitleFont">Tammy</h2>
          </Row>
          
        </Animated>

        <Row className="Spacer"/>
        <Row className="Spacer"/>
        <div>
          <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={this.props.showHideTammyDeets}>
                
                <Row className="Social-Icons">
                <CardGroup className="FooterCenter">
                  
                 

                    <div className="service-cont">
                  <Animated className="FooterCenter" animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={this.props.showHideToriMain}> 
                    
                      <Col lg={6} sm={12}>
                      <Card className="App-mainbg2">
                        <Card.Img variant="top" src={HaircutIcon} className="App-logo"/>
                        <Card.Title style={{'font-size' : '10vmin', 'text-align':'center'}} className="text-black-moon">Haircut<br/> Services</Card.Title>
                       
                        <Card.Body>
                          <Card.Text className="text-outline-gar">
                          
                          </Card.Text>
                          <Card.Img className="BefAftImg" src={"https://firebasestorage.googleapis.com/v0/b/lure-beauty-bar.appspot.com/o/ServicesPhotos%2FTammyCut1.jpg?alt=media&token=be7568d3-3278-4c8a-9e40-5922f672f109"}>
                                
                          </Card.Img>
                          <Accordion className="service-card" eventKey="0">
                        
                  
                            {
                              Haircuts.map((service, index) =>
                                (<Card className="service-card">
                              <Card.Header className="btn-servicedesc" eventKey={JSON.stringify(index)}>
                                {service.name}<Image className="image-padding" src={LBBtiny}/>{service.starting ? " Starting At " : ""}${service.price}
                              </Card.Header>
                            </Card>))
                            }
                            
                          </Accordion>
                        </Card.Body>
                        <Card.Body>
                        <Card.Text className="text-outline-gar">

                                
                        </Card.Text>
                        </Card.Body>
                        </Card>
                      </Col>
                      <Col lg={6} sm={12}>
                      <Card className="App-mainbg2">
                        <Card.Img variant="top" src={HairstyleIcon} className="App-logo"/>
                        <Card.Title style={{'font-size' : '10vmin', 'text-align':'center'}} className="text-black-moon">Styling<br/> Services</Card.Title>
                       
                        <Card.Body>
                          <Card.Text className="text-outline-gar">
                            
                          </Card.Text>
                          <Card.Img className="BefAftImg" src={"https://firebasestorage.googleapis.com/v0/b/lure-beauty-bar.appspot.com/o/ServicesPhotos%2FTammyStyle1.jpg?alt=media&token=a4e6b624-cba2-498d-9175-d64ad20acb47"}>
                                
                          </Card.Img>
                          <Accordion className="service-card" eventKey="0">
                            {
                              HairStyles.map((service, index) =>
                                (<Card className="service-card">
                              <Card.Header className="btn-servicedesc" eventKey={JSON.stringify(index)}>
                                {service.name}<Image className="image-padding" src={LBBtiny}/>{service.starting ? " Starting At " : ""}${service.price}
                              </Card.Header>
                            </Card>))
                            }
                          </Accordion>
                        </Card.Body>
                        </Card>
                      </Col>
                    
                  </Animated>
                  </div>
                  </CardGroup>  
                  </Row>

<Row className="Spacer"/>
<Row className="Spacer"/>

                
          </Animated>
        </div>
      </Container>
    );
  }
}

export default TammyDeets;