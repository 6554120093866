import React from 'react';
import {AnimatedOnScroll} from "react-animated-css-onscroll";
import './App.css';
import Clock from './Clock.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import LBB from './LBBlogo.png';
import {Animated} from "react-animated-css";
import ToriServicesPic from './ToriAboutPic2.JPG';
import LoriServicesPic from './LoriAboutPic.jpeg';
import TammyServicesPic from './TammyAboutPic.jpeg';



class AboutUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "AboutUs",
      HomeActive: false,
                        AboutUsActive: true,
                        ServicesActive: false,
                        ContactActive: false
    };
  }


  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
  }

  render() {
    return (

      <Container fluid>
      <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={this.props.showHideAboutUs}>
              
        <Row className="Social-Icons">
          <h1 className="TitleFont2">About</h1>
        </Row>
        
        </Animated>
        <br/>
        <Row className="Spacer"/>
        <Container  fluid>
        <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={this.props.showHideAboutUs}>
       
          <Row className="Social-Icons">
            <Col className="Social-Icons" lg={12}>
            <AnimatedOnScroll animationIn="fadeIn" animationOut="fadeOut">
              <Card className="Media-Icons">
                <Image className="FaceIcon" src={"https://firebasestorage.googleapis.com/v0/b/lure-beauty-bar.appspot.com/o/ProfilePhotos%2FToriAboutPic2.JPG?alt=media&token=1dd50ee6-35de-4002-a359-b5656a4d0f45"} roundedCircle/>
                <Card.Body className="ProfileDesc">
                <br/>
                  <h3 style={{'fontSize' : '30px'}} className="text-black-gar-bold">Victoria</h3>
                  <p className="text-black-gar">
                    Owner, Tan Artist & Brow Stylist
                  </p>
                  <br/>
                </Card.Body>
              </Card>
              </AnimatedOnScroll>
            </Col>
          </Row>
          </Animated>
        </Container>
      </Container>
    );
  }
}

export default AboutUs;