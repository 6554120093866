import React from 'react';
import {AnimatedOnScroll} from "react-animated-css-onscroll";
import logo from './logo.svg';
import './App.css';
import Clock from './Clock.js';
import AboutUs from './AboutUs.js';
import Contact from './Contact.js';
import Services from './Services.js';
import Products from './Products.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';
import LBB from './LBBlogo.png';
import LURE from './Lure.PNG';
import bolhuisMC from './bolhuisMCLure.PNG';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar'
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import FB from './FB-glyph.png';
import FB2 from './FindUsOn_Header_2019.png';
import IG from './Glyph-Icon-IG.png';
import IG2 from './IG-logo-black-and-white-transparent.png';
import CALL from './Call-Glyph.png';
import EM from './Email-Glyph.png';
import {Animated} from "react-animated-css";
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { withRouter } from 'react-router-dom';
import HairPic1 from './HairPic1.png';
import HairPic2 from './HairPic2.jpg';
import HairPic3 from './HairPic3.jpg';
import TanPic1 from './TanPic1.jpg';
import TanPic2 from './TanPic2.jpg';
import TanPic3 from './TanPic3.jpg';
import LoriPic1 from './LoriPic1.jpg';
import LoriPic2 from './LoriPic2.jpg';
import LoriPic3 from './LoriPic3.jpg';
import BrowPic1 from './BrowPic1.jpg';
import ToriBrows1 from './ToriBrows1.jpeg';
import ToriBrows2 from './ToriBrows2.JPG';
import PalmLeavesL from './PalmLeavesL.png';
import PalmLeavesR from './PalmLeavesR.png';


class Main extends React.Component {

  constructor() {
    super();
    this.state = {
      name: "Main",
      showHideHome: true,
      showHideAboutUs: false,
      showHideContact: false,
      showHideServices: false,
      HomeActive: true,
      AboutUsActive: false,
      ServicesActive: false,
      ContactActive: false
    };
    this.hideComponent = this.hideComponent.bind(this);
  }

  hideComponent(name) {
    console.log(name);
    switch (name) {
      case "Home":
        this.setState({ showHideHome: true,
                        showHideAboutUs: false,
                        showHideContact: false,
                        showHideServices: false,
                        HomeActive: true,
                        AboutUsActive: false,
                        ServicesActive: false,
                        ContactActive: false });
        break;
      case "AboutUs":
        this.setState({ showHideAboutUs: true,
                        showHideHome: false,
                        showHideContact: false,
                        showHideServices: false,
                        HomeActive: false,
                        AboutUsActive: true,
                        ServicesActive: false,
                        ContactActive: false });
        break;
      case "Contact":
        this.setState({ showHideContact: true,
                        showHideHome: false,
                        showHideAboutUs: false,
                        showHideServices: false,
                        HomeActive: false,
                        AboutUsActive: false,
                        ServicesActive: false,
                        ContactActive: true });
        break;
      case "Services":
        this.setState({ showHideContact: false,
                        showHideHome: false,
                        showHideAboutUs: false,
                        showHideServices: true,
                        HomeActive: false,
                        AboutUsActive: false,
                        ServicesActive: true,
                        ContactActive: false });
        break;
    }
  }



  render(){

    const { showHideHome, showHideAboutUs, showHideContact, showHideServices, HomeActive, AboutUsActive, ServicesActive, ContactActive } = this.state;
    return (
      
    <Container fluid className="App-mainbg2">

      <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={5000} animationOutDuration={5000} isVisible={true}>
      <Row >
        <Col  xs>
          <Row>
            <Col xs={12} className="HeadTitleCol">
              <Image src={LURE} className="TitleImg"/>
            </Col>
          </Row>
          <br/>
          <br/>
          <Row className="SocialCont">
            <Col xs>
              <Container fluid>
                  <Row className="Social-Icons">
                    <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={5000} animationOutDuration={5000} isVisible={true}>
                      <h1 className="TitleFont">Welcome!</h1>
                      <h2 className="text-outline-gar2">Notice: The new location is 18 Sparling Crescent in St. Marys, Ontario.</h2>
                    </Animated>
                  </Row>
              <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={5000} animationOutDuration={5000} isVisible={true}>
              
              <Carousel fade="true" pause="hover" className="Carousel">
                <Carousel.Item>
                  <Row>
                    <Col >
                      <Image rounded src={"https://firebasestorage.googleapis.com/v0/b/lure-beauty-bar.appspot.com/o/CarouselPhotos%2FToriBrows1.jpeg?alt=media&token=234f7e47-1fd7-45a2-940c-1f5b12e9ebc6"} width="100%" height="100%"/>
                    </Col>
                    <Col >
                      <Image rounded src={"https://firebasestorage.googleapis.com/v0/b/lure-beauty-bar.appspot.com/o/CarouselPhotos%2FBrowPic1.jpg?alt=media&token=8ae42cb7-ad0d-4233-b822-c809e336bb43"} width="100%" height="100%"/>
                    </Col>
                    <Col>
                      <Image rounded src={"https://firebasestorage.googleapis.com/v0/b/lure-beauty-bar.appspot.com/o/CarouselPhotos%2FToriBrows2.JPG?alt=media&token=27a478e0-3261-4653-bddd-8c5fd22291f0"} width="100%" height="100%"/>
                    </Col>
                  </Row>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <Carousel.Caption className="text-outline-gar2">
                    Brows by Victoria
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item >
                  <Row >
                    <Col>
                      <Image rounded src={"https://firebasestorage.googleapis.com/v0/b/lure-beauty-bar.appspot.com/o/CarouselPhotos%2FTanPic1.jpg?alt=media&token=feff6b15-dbaf-4820-aaac-61eb5d2c3c6d"} width="100%"/>
                    </Col>
                    <Col >
                      <Image rounded src={"https://firebasestorage.googleapis.com/v0/b/lure-beauty-bar.appspot.com/o/CarouselPhotos%2FTanPic3.jpg?alt=media&token=5ab8fb10-7416-4ee9-b4d8-aa5bd5392ee0"} width="100%"/>
                    </Col>
                    <Col>
                      <Image rounded src={"https://firebasestorage.googleapis.com/v0/b/lure-beauty-bar.appspot.com/o/CarouselPhotos%2FTanPic2.jpg?alt=media&token=2f15df40-f4b8-4459-b7b1-851842d22556"} width="100%"/>
                    </Col>
                  </Row>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <Carousel.Caption className="text-outline-gar2">
                    Tans by Victoria
                  </Carousel.Caption>
                </Carousel.Item>
                
              </Carousel>
              </Animated>
            </Container>
            </Col>
          </Row>
        </Col>
      </Row>
      </Animated>
    </Container>
    );
  }
}

export default withRouter(Main);
