import React from 'react';
import {AnimatedOnScroll} from "react-animated-css-onscroll";
import './App.css';
import Clock from './Clock.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';
import LBB from './LBBlogo.png';
import LBBtiny from './LBBlogo-tiny.png';
import VeganLogo from './VeganLogo.png';
import SprayTanLogo from './SprayTanLogo.png';
import HairDressingIcon from './HairdressingIcon.png';
import InjectablesIcon from './InjectablesIcon.png';
import WaxingIcon from './WaxingIcon.png';
import MakeupIcon from './MakeupIcon.png';
import ThreadingIcon from './ThreadingIcon.png';
import HairScissorsIcon from './HairScissorsIcon.png';
import HairDyeingIcon from './HairDyeingIcon.png';
import BrowPic from './BrowPic.png';
import BrowPicR from './BrowPicR.png';
import {Animated} from "react-animated-css";
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import {db} from "./firebase.js";
import BotoxIcon from './BotoxIcon2.png';
import DermalIcon from './DermalIcon2.png';
import LoriWrinkle1 from './LoriWrinkle1.jpg';
import LoriLip1 from './LoriLip1.jpg';
import LoriServicesPic from './LoriAboutPic.jpeg';


class LoriDeets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "LoriDeets",
      showHideLoriDeets: true,
      showHideToriMain: true,
      showHideTammyMain: true,
      showHideLoriMain: true,
      showHideLivMain: true,
      showHideToriDeets: false,
      showHideTammyDeets: false,
      showHideLoriDeets: false,
      showHideLivDeets: false,
      ShowHideBack: false,
      FillerServices: [],
      OtherServices: []
    };
    this.hideComponent = this.hideComponent.bind(this);
  }



  hideComponent(name) {
    console.log(name);
    switch (name) {
      case "Main":
        this.setState({ showHideToriMain: true,
                        showHideTammyMain: true,
                        showHideLoriMain: true,
                        showHideLivMain: true,
                        showHideToriDeets: false,
                        showHideTammyDeets: false,
                        showHideLoriDeets: false,
                        showHideLivDeets: false,
                        showHideBack: false });
        break;
      case "Tori":
        this.setState({ showHideToriMain: false,
                        showHideTammyMain: false,
                        showHideLoriMain: false,
                        showHideLivMain: false,
                        showHideToriDeets: true,
                        showHideTammyDeets: false,
                        showHideLoriDeets: false,
                        showHideLivDeets: false,
                        showHideBack: true });
        break;
      case "Tammy":
        this.setState({ showHideToriMain: false,
                        showHideTammyMain: false,
                        showHideLoriMain: false,
                        showHideLivMain: false,
                        showHideToriDeets: false,
                        showHideTammyDeets: true,
                        showHideLoriDeets: false,
                        showHideLivDeets: false,
                        showHideBack: true });
        break;
      case "Lori":
        this.setState({ showHideToriMain: false,
                        showHideTammyMain: false,
                        showHideLoriMain: false,
                        showHideLivMain: false,
                        showHideToriDeets: false,
                        showHideTammyDeets: false,
                        showHideLoriDeets: true,
                        showHideLivDeets: false,
                        showHideBack: true });
        break;
      case "Liv":
        this.setState({ showHideToriMain: false,
                        showHideTammyMain: false,
                        showHideLoriMain: false,
                        showHideLivMain: false,
                        showHideToriDeets: false,
                        showHideTammyDeets: false,
                        showHideLoriDeets: false,
                        showHideLivDeets: true,
                        showHideBack: true });
        break;
    }
  }

  componentDidMount() {

    window.scrollTo(0, 50);

    this.setState(this.props.state);

    db.collection("LoriFillers").get().then((querySnapshot) => {
      const data = querySnapshot.docs.map(doc => doc.data());
      this.setState({FillerServices: data});
        querySnapshot.forEach((doc) => {
            console.log(`${doc.id} => ${doc.data()}`);
        });
    });

    db.collection("LoriOthers").get().then((querySnapshot) => {
      const data = querySnapshot.docs.map(doc => doc.data());
      this.setState({OtherServices: data});
        querySnapshot.forEach((doc) => {
            console.log(`${doc.id} => ${doc.data()}`);
        });
    });

  }

  componentWillUnmount() {
  }

  render() {
    const { showHideBack, 
            showHideToriMain, 
            showHideTammyMain, 
            showHideLoriMain, 
            showHideLivMain, 
            showHideToriDeets, 
            showHideTammyDeets, 
            showHideLoriDeets, 
            showHideLivDeets,
            FillerServices,
            OtherServices } = this.state;
    
    return (

      <Container fluid>

      <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={this.props.showHideServices}>      
          <Row className="Social-Icons">
            <Image className="FaceIcon" src={"https://firebasestorage.googleapis.com/v0/b/lure-beauty-bar.appspot.com/o/ProfilePhotos%2FLoriAboutPic.jpeg?alt=media&token=797a8596-8276-497f-835a-2a9319a38fdc"} roundedCircle/>
          </Row>
          <br/>
          <Row className="Social-Icons">
            <h2 className="TitleFont">Lori</h2>
          </Row>
          
        </Animated>

        <Row className="Spacer"/>
        <Row className="Spacer"/>
        <div>
          <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={this.props.showHideLoriDeets}>
                
                <Row className="Social-Icons">
                  <CardGroup className="FooterCenter">
                 

                  <div className="service-cont">
                  <Animated className="FooterCenter" animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={this.props.showHideToriMain}> 
                    
                    <Col lg={6} sm={12}>
                      <Card className="App-mainbg2">
                        <Row className="FooterCenter">
                          <Card.Img src={BotoxIcon} className="App-logo"/>
                        </Row>
                        <Card.Title style={{'font-size' : '10vmin', 'text-align':'center'}} className="text-black-moon">Cosmetic<br/> Injectable<br/> Services</Card.Title>
                        
                        <Card.Body>
                          <Card.Text className="text-black-gar">
                           
                          </Card.Text>
                          <Card.Img className="BefAftImg" src={"https://firebasestorage.googleapis.com/v0/b/lure-beauty-bar.appspot.com/o/ServicesPhotos%2FLoriWrinkle1.jpg?alt=media&token=3aa12d8a-1d68-4517-a210-524322786252"}>
                                
                          </Card.Img>
                          <Accordion className="service-card" eventKey="0">
                        
                  
                            {
                              FillerServices.map((service, index) =>
                                (<Card className="service-card">
                              <Card.Header className="btn-servicedesc" eventKey={JSON.stringify(index)}>
                                {service.name}<Image className="image-padding" src={LBBtiny}/>${service.price}
                              </Card.Header>
                            </Card>))
                            }
                            
                          </Accordion>
                        </Card.Body>
                        
                        </Card>
                    </Col>
                    <Col lg={6} sm={12}>
                      <Card className="App-mainbg2">
                        <Row className="FooterCenter">
                          <Card.Img src={DermalIcon} className="App-logo"/>
                        </Row>
                        <Card.Title style={{'font-size' : '10vmin', 'text-align':'center'}} className="text-black-moon">Other<br/> Dermal<br/> Services</Card.Title>
                        
                        <Card.Body>
                          <Card.Text className="text-black-gar">
                           
                          </Card.Text>
                          <Card.Img className="BefAftImg" src={"https://firebasestorage.googleapis.com/v0/b/lure-beauty-bar.appspot.com/o/ServicesPhotos%2FLoriLip1.jpg?alt=media&token=dec6e05e-bef3-4794-9c56-7e7aeb44d9be"}>
                                
                          </Card.Img>
                          <Accordion className="service-card" eventKey="0">
                            {
                              OtherServices.map((service, index) =>
                                (<Card className="service-card">
                              <Card.Header className="btn-servicedesc" eventKey={JSON.stringify(index)}>
                                {service.name}<Image className="image-padding" src={LBBtiny}/>${service.price}
                              </Card.Header>
                            </Card>))
                            }
                          </Accordion>
                        </Card.Body>
                        </Card>
                      </Col>
                    
                  </Animated>
                  </div>
                    </CardGroup>
                  </Row>

<Row className="Spacer"/>
<Row className="Spacer"/>

                  
                
          </Animated>
          </div>
      </Container>
    );
  }
}

export default LoriDeets;