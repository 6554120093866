import React from 'react';
import {AnimatedOnScroll} from "react-animated-css-onscroll";
import './App.css';
import Clock from './Clock.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';
import LBB from './LBBlogo.png';
import LBBtiny from './LBBlogo-tiny.png';
import VeganLogo from './VeganLogo.png';
import SprayTanLogo from './SprayTanLogo2.png';
import HairDressingIcon from './HairdressingIcon2.png';
import InjectablesIcon from './InjectablesIcon2.png';
import BridalIcon from './BridalMakeupIcon2.png';
import WaxingIcon from './WaxingIcon2.png';
import ThreadingIcon from './ThreadingIcon2.png';
import HairScissorsIcon from './HairScissorsIcon2.png';
import HairDyeingIcon from './HairDyeingIcon2.png';
import BrowPic from './BrowPic.png';
import BrowPicR from './BrowPicR.png';
import {Animated} from "react-animated-css";
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import ToriDeets from './ToriDeets.js';
import TammyDeets from './TammyDeets.js';
import LoriDeets from './LoriDeets.js';
import BridalDeets from './BridalDeets.js';
import { BrowserRouter, Route, Link, Switch, useParams,
  useRouteMatch } from "react-router-dom";


class Services extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "Services",
      showHideServices: true,
      showHideToriMain: true,
      showHideTammyMain: true,
      showHideLoriMain: true,
      showHideLivMain: true,
      showHideTaylorMain: true,
      showHideToriDeets: false,
      showHideTammyDeets: false,
      showHideLoriDeets: false,
      showHideLivDeets: false,
      showHideTaylorDeets: false,
      ShowHideBack: false,
      HomeActive: false,
                        AboutUsActive: false,
                        ServicesActive: true,
                        ContactActive: false
    };
    this.hideComponent = this.hideComponent.bind(this);
  }

  hideComponent(name) {
    console.log(name);
    switch (name) {
      case "Main":
        window.scrollTo(0, 50);
        this.setState({ showHideToriMain: true,
                        showHideTammyMain: true,
                        showHideLoriMain: true,
                        showHideLivMain: true,
                        showHideTaylorMain: true,
                        showHideToriDeets: false,
                        showHideTammyDeets: false,
                        showHideLoriDeets: false,
                        showHideLivDeets: false,
                        showHideTaylorDeets: false,
                        showHideBack: false });
        break;
      case "Tori":
        this.setState({ showHideToriMain: false,
                        showHideTammyMain: false,
                        showHideLoriMain: false,
                        showHideLivMain: false,
                        showHideTaylorMain: false,
                        showHideToriDeets: true,
                        showHideTammyDeets: false,
                        showHideLoriDeets: false,
                        showHideLivDeets: false,
                        showHideTaylorDeets: false,
                        showHideBack: true });
        break;
      case "Tammy":
        this.setState({ showHideToriMain: false,
                        showHideTammyMain: false,
                        showHideLoriMain: false,
                        showHideLivMain: false,
                        showHideTaylorMain: false,
                        showHideToriDeets: false,
                        showHideTammyDeets: true,
                        showHideLoriDeets: false,
                        showHideLivDeets: false,
                        showHideTaylorDeets: false,
                        showHideBack: true });
        break;
      case "Lori":
        this.setState({ showHideToriMain: false,
                        showHideTammyMain: false,
                        showHideLoriMain: false,
                        showHideLivMain: false,
                        showHideTaylorMain: false,
                        showHideToriDeets: false,
                        showHideTammyDeets: false,
                        showHideLoriDeets: true,
                        showHideLivDeets: false,
                        showHideTaylorDeets: false,
                        showHideBack: true });
        break;
      case "Olivia":
        this.setState({ showHideToriMain: false,
                        showHideTammyMain: false,
                        showHideLoriMain: false,
                        showHideLivMain: false,
                        showHideTaylorMain: false,
                        showHideToriDeets: false,
                        showHideTammyDeets: false,
                        showHideLoriDeets: false,
                        showHideLivDeets: true,
                        showHideTaylorDeets: false,
                        showHideBack: true });
        break;
      case "Taylor":
        this.setState({ showHideToriMain: false,
                        showHideTammyMain: false,
                        showHideLoriMain: false,
                        showHideLivMain: false,
                        showHideTaylorMain: false,
                        showHideToriDeets: false,
                        showHideTammyDeets: false,
                        showHideLoriDeets: false,
                        showHideLivDeets: false,
                        showHideTaylorDeets: true,
                        showHideBack: true });
        break;
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
  }

  render() {
    const { showHideBack, showHideServices, showHideToriMain, showHideTammyMain, showHideLoriMain, showHideLivMain, showHideTaylorMain, showHideToriDeets, showHideTammyDeets, showHideLoriDeets, showHideLivDeets, showHideTaylorDeets } = this.state;
      

    return (

      <Container fluid>
        <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={this.props.showHideServices}>      
          <Row className="Social-Icons">
            <h1 className="TitleFont2">Services</h1>
          </Row>
        </Animated>
        <Row className="Spacer"/>
        <Row className="Spacer"/>
        

        <Switch>
          <Route exact path="/services">
            <Container fluid>
          <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={this.props.showHideServices}>
                
                <Row className="Social-Icons">
                  
                  <Col lg={12} className="Social-Icons2">
                  <AnimatedOnScroll animationIn="fadeIn" animationOut="fadeOut">
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 250 }}
                    overlay={<Tooltip id="button-tooltip">
                                Tap to view Victoria's services
                              </Tooltip>}
                  >
                    <Link to="/services/victoria" style={{'text-decoration' : 'none'}}>
                      <Card className="App-mainbg2">
                        <Row className="Social-Icons2">
                          <Card.Img src={SprayTanLogo} className="App-logo"/>
                          <Card.Img src={ThreadingIcon} className="App-logo"/>
                          <Card.Img src={WaxingIcon} className="App-logo"/>
                        </Row>
                        <Card.Title className="btn-number">Victoria</Card.Title>
                        <Card.Body>
                          
                          <Card.Text className="text-black-gar">
                            Spray Tanning ○ 
                            Waxing ○
                            Threading
                          </Card.Text>
                        </Card.Body>
                        <br/>
                      </Card>
                    </Link>
                  </OverlayTrigger>
                  </AnimatedOnScroll>
                  </Col>
                  
                  
                  
                  
                  </Row>


                  <Row className="Social-Icons">
                  
                  
                  <Col lg={12} className="Social-Icons2">
                  <AnimatedOnScroll animationIn="fadeIn" animationOut="fadeOut">
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 250 }}
                    overlay={<Tooltip id="button-tooltip">
                                Tap to view Bridal services
                              </Tooltip>}
                  >

                  
                    <Link to="/services/bridal" style={{'text-decoration' : 'none'}}>
                      <Card className="App-mainbg2">
                      <Row className="Social-Icons2">
                        <Card.Img variant="top" src={BridalIcon} className="App-logo"/>
                        </Row>
                        <Card.Title className="btn-number">Bridal & Event</Card.Title>
                        <Card.Body>
                          
                          <Card.Text className="text-black-gar">
                            Spray Tanning
                          </Card.Text>
                        </Card.Body>
                        <br/>
                      </Card>
                    </Link>
                  </OverlayTrigger>
                  </AnimatedOnScroll>
                  </Col>
                  
                  
                  


                  
                  </Row>
                
          </Animated>
        </Container>
          </Route>
          <Route path="/services/victoria">
            <ToriDeets state={this.state}/>
          </Route>
          <Route path="/services/bridal">
            <BridalDeets state={this.state}/>
          </Route>
        </Switch>
      </Container>
    );
  }
}

export default Services;