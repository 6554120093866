import React from 'react';
import {AnimatedOnScroll} from "react-animated-css-onscroll";
import './App.css';
import Clock from './Clock.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';
import LBB from './LBBlogo.png';
import LBBtiny from './LBBlogo-tiny.png';
import VeganLogo from './VeganLogo2.png';
import SprayTanLogo from './SprayTanLogo2.png';
import HairDressingIcon from './HairdressingIcon.png';
import InjectablesIcon from './InjectablesIcon.png';
import WaxingIcon from './WaxingIcon2.png';
import WaxingIcon2 from './WaxingIcon4.png';
import WaxingIcon3 from './WaxingIcon6.png';
import WaxingIcon4 from './WaxingIcon8.png';
import MakeupIcon from './MakeupIcon.png';
import ThreadingIcon from './ThreadingIcon.png';
import HairScissorsIcon from './HairScissorsIcon.png';
import HairDyeingIcon from './HairDyeingIcon.png';
import BrowPic from './BrowPic2.png';
import BrowPicR from './BrowPicR2.png';
import {Animated} from "react-animated-css";
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import {db} from "./firebase.js";
import ToriBrowWax1 from './ToriBrowWax1.jpg';
import ToriSprayTan1 from './ToriSprayTan1.jpg';
import ToriLegWax1 from './ToriLegWax1.jpg';
import ToriServicesPic from './ToriAboutPic.jpeg';



class ToriDeets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "ToriDeets",
      showHideToriDeets: true,
      showHideToriMain: true,
      showHideTammyMain: true,
      showHideLoriMain: true,
      showHideLivMain: true,
      showHideToriDeets: false,
      showHideTammyDeets: false,
      showHideLoriDeets: false,
      showHideLivDeets: false,
      ShowHideBack: false,
      SprayTanning: [],
      BrowServices: [],
      Waxing1: [],
      Waxing2: [],
      Waxing3: []
    };
    this.hideComponent = this.hideComponent.bind(this);
  }



  hideComponent(name) {
    console.log(name);
    switch (name) {
      case "Main":
        this.setState({ showHideToriMain: true,
                        showHideTammyMain: true,
                        showHideLoriMain: true,
                        showHideLivMain: true,
                        showHideToriDeets: false,
                        showHideTammyDeets: false,
                        showHideLoriDeets: false,
                        showHideLivDeets: false,
                        showHideBack: false });
        break;
      case "Tori":
        this.setState({ showHideToriMain: false,
                        showHideTammyMain: false,
                        showHideLoriMain: false,
                        showHideLivMain: false,
                        showHideToriDeets: true,
                        showHideTammyDeets: false,
                        showHideLoriDeets: false,
                        showHideLivDeets: false,
                        showHideBack: true });
        break;
      case "Tammy":
        this.setState({ showHideToriMain: false,
                        showHideTammyMain: false,
                        showHideLoriMain: false,
                        showHideLivMain: false,
                        showHideToriDeets: false,
                        showHideTammyDeets: true,
                        showHideLoriDeets: false,
                        showHideLivDeets: false,
                        showHideBack: true });
        break;
      case "Lori":
        this.setState({ showHideToriMain: false,
                        showHideTammyMain: false,
                        showHideLoriMain: false,
                        showHideLivMain: false,
                        showHideToriDeets: false,
                        showHideTammyDeets: false,
                        showHideLoriDeets: true,
                        showHideLivDeets: false,
                        showHideBack: true });
        break;
      case "Liv":
        this.setState({ showHideToriMain: false,
                        showHideTammyMain: false,
                        showHideLoriMain: false,
                        showHideLivMain: false,
                        showHideToriDeets: false,
                        showHideTammyDeets: false,
                        showHideLoriDeets: false,
                        showHideLivDeets: true,
                        showHideBack: true });
        break;
    }
  }

  componentDidMount() {

    window.scrollTo(0, 50);

    this.setState(this.props.state);

    db.collection("ToriSprayTanning").get().then((querySnapshot) => {
      const data = querySnapshot.docs.map(doc => doc.data());
      this.setState({SprayTanning: data});
        querySnapshot.forEach((doc) => {
            console.log(`${doc.id} => ${doc.data()}`);
        });
    });

    db.collection("ToriBrows").get().then((querySnapshot) => {
      const data = querySnapshot.docs.map(doc => doc.data());
      this.setState({BrowServices: data});
        querySnapshot.forEach((doc) => {
            console.log(`${doc.id} => ${doc.data()}`);
        });
    });

    db.collection("ToriWaxingOne").get().then((querySnapshot) => {
      const data = querySnapshot.docs.map(doc => doc.data());
      this.setState({Waxing1: data});
        querySnapshot.forEach((doc) => {
            console.log(`${doc.id} => ${doc.data()}`);
        });
    });

    db.collection("ToriWaxingTwo").get().then((querySnapshot) => {
      const data = querySnapshot.docs.map(doc => doc.data());
      this.setState({Waxing2: data});
        querySnapshot.forEach((doc) => {
            console.log(`${doc.id} => ${doc.data()}`);
        });
    });

    db.collection("ToriWaxingThree").get().then((querySnapshot) => {
      const data = querySnapshot.docs.map(doc => doc.data());
      this.setState({Waxing3: data});
        querySnapshot.forEach((doc) => {
            console.log(`${doc.id} => ${doc.data()}`);
        });
    });

  }

  componentWillUnmount() {
  }

  render() {
    const { showHideBack, 
            showHideToriMain, 
            showHideTammyMain, 
            showHideLoriMain, 
            showHideLivMain, 
            showHideToriDeets, 
            showHideTammyDeets, 
            showHideLoriDeets, 
            showHideLivDeets,
            SprayTanning,
            BrowServices,
            Waxing1,
            Waxing2,
            Waxing3 } = this.state;
    
    return (

      <Container fluid>

      <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={this.props.showHideServices}>      
          
          <Row className="Social-Icons">
            <Image className="FaceIcon" src={"https://firebasestorage.googleapis.com/v0/b/lure-beauty-bar.appspot.com/o/ProfilePhotos%2FToriAboutPic.jpeg?alt=media&token=7d5bf657-9331-4f6a-b7ce-11cdc7c4e715"} roundedCircle/>
          </Row>
          <br/>
          <Row className="Social-Icons">
            <h3 className="TitleFont">Victoria</h3>
          </Row>
        </Animated>

        <Row className="Spacer"/>
        <Row className="Spacer"/>
        <div>
          <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={this.props.showHideToriDeets}>
                
                <Row className="Social-Icons">
                <CardGroup className="FooterCenter">
                  
                 

                <div className="service-cont">
                  <Animated className="FooterCenter" animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={this.props.showHideToriMain}> 
                      <Col lg={6} sm={12}>
                      <Card className="App-mainbg2">
                      <Row className="FooterCenter">
                          <Card.Img src={SprayTanLogo} className="App-logo"/>
                          <Card.Img src={VeganLogo} className="App-logo"/>
                        </Row>
                        <Card.Title style={{'font-size' : '10vmin', 'text-align':'center'}} className="text-black-moon">Spray Tanning</Card.Title>
                        
                        <Card.Body>
                          <Card.Text className="text-black-gar">
                            Victoria's tans use all-natural solution from NUDA tanning, leaving you with a natural bronze glow and no orange tint!
                          </Card.Text>
                          <Card.Img className="BefAftImg" src={"https://firebasestorage.googleapis.com/v0/b/lure-beauty-bar.appspot.com/o/ServicesPhotos%2FToriSprayTan1.jpg?alt=media&token=3abea91c-08e9-4717-8f53-323ba9b99585"}>
                                
                          </Card.Img>
                        
                          <Accordion className="service-card" eventKey="0">
                            {
                              SprayTanning.map((service, index) =>
                                (<Accordion.Item className="service-card" eventKey={JSON.stringify(index)}>
                              <Accordion.Header className="btn-servicedesc" style={{'paddingBottom':'5px'}} >
                                {service.name}<Image className="image-padding" src={LBBtiny}/>${service.price}
                              </Accordion.Header>

                              <Accordion.Body className="service-card">
                                <Card.Text className="text-black-gar">
                                  {service.description}
                                </Card.Text>
                              </Accordion.Body>
                            </Accordion.Item>))
                            }
                          </Accordion>
                        </Card.Body>
                        <Card.Body>
                        
                        </Card.Body>
                        </Card>
                      </Col>

                    <Col lg={6} sm={12}>
                      <Card className="App-mainbg2">
                        <Row className="FooterCenter">
                          <Card.Img src={BrowPic} className="App-logo"/>
                          <Card.Img src={BrowPicR} className="App-logo"/>
                        </Row>
                        <Card.Title style={{'font-size' : '10vmin', 'text-align':'center'}} className="text-black-moon">Brow Services</Card.Title>
                        
                        <Card.Body>
                          <Card.Text className="text-black-gar">
                            Brows are Victoria's specialty, she'll work with you to create your dream brows then brush & style them for you. One look in the mirror and you'll be in love with them!
                          </Card.Text>
                          <Card.Img className="BefAftImg" src={"https://firebasestorage.googleapis.com/v0/b/lure-beauty-bar.appspot.com/o/ServicesPhotos%2FToriBrowWax1.jpg?alt=media&token=07d615c2-939e-441e-985b-0d40cb0347f2"}>

                                
                        </Card.Img>
                          <Accordion className="service-card" eventKey="0">
                            {
                              BrowServices.map((service, index) =>
                                (<Card className="service-card">
                              <Card.Header className="btn-servicedesc" eventKey={JSON.stringify(index)}>
                                {service.name}<Image className="image-padding" src={LBBtiny}/>${service.price}
                              </Card.Header>
                            </Card>))
                            }
                          </Accordion>
                          
                        </Card.Body>
                        </Card>
                      </Col>
                  </Animated>
                  </div>
                  </CardGroup>  
                  </Row>

<Row className="Spacer"/>
<Row className="Spacer"/>

                  <Row className="Social-Icons">
                    <Row className="Social-Icons">
                      <h1 style={{'font-size' : '10vmin', 'text-align':'center'}} className="text-black-moon">All Waxing Services</h1>
                    </Row>
                  <CardGroup className="FooterCenter">
                  <div className="service-cont">
                  <Animated className="FooterCenter" animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={this.props.showHideToriMain}> 
                    
                      <Col lg={4} sm={12}>
                      <Card className="App-mainbg2">
                        <Card.Img variant="top" src={WaxingIcon3} className="App-logo"/>
                        <Card.Body>
                          
                          <Card.Text className="text-black-gar" style={{'fontSize':'5vmin'}}>
                            Women's Waxing
                          </Card.Text>
                          <Accordion className="service-card" eventKey="0">
                            {
                              Waxing1.map((service, index) =>
                                (<Card className="service-card">
                              <Card.Header className="btn-servicedesc" eventKey={JSON.stringify(index)}>
                                {service.name}<Image className="image-padding" src={LBBtiny}/>${service.price}
                              </Card.Header>
                            </Card>))
                            }
                            
                          </Accordion>
                        </Card.Body>
                      </Card>
                      </Col>
                      <Col lg={4} sm={12}>
                      <Card className="App-mainbg2">
                        <Card.Img variant="top" src={WaxingIcon2} className="App-logo"/>
                        <Card.Body>
                          
                          <Card.Text className="text-outline-gar" style={{'fontSize':'5vmin'}}>
                            <br/>
                          </Card.Text>
                          
                          <Accordion className="service-card" eventKey="0">
                            {
                              Waxing2.map((service, index) =>
                                (<Card className="service-card">
                              <Card.Header className="btn-servicedesc" eventKey={JSON.stringify(index)}>
                                {service.name}<Image className="image-padding" src={LBBtiny}/>${service.price}
                              </Card.Header>
                            </Card>))
                            }
                            
                          </Accordion>

                        </Card.Body>
                        <Card.Img className="BefAftImg" src={"https://firebasestorage.googleapis.com/v0/b/lure-beauty-bar.appspot.com/o/ServicesPhotos%2FToriLegWax1.jpg?alt=media&token=5fbe7a9c-8eb6-4d4e-8c7f-fe952867e453"}>
                                
                          </Card.Img>
                      </Card>
                      
                      </Col>
                      <Col lg={4} sm={12}>
                      <Card className="App-mainbg2">
                        <Card.Img variant="top" src={WaxingIcon4} className="App-logo"/>
                        <Card.Body>
                          
                          <Card.Text className="text-black-gar" style={{'fontSize':'5vmin'}}>
                            Men's Waxing
                          </Card.Text>
                          <Accordion className="service-card" eventKey="0">
                            {
                              Waxing3.map((service, index) =>
                                (<Card className="service-card">
                              <Card.Header className="btn-servicedesc" eventKey={JSON.stringify(index)}>
                                {service.name}<Image className="image-padding" src={LBBtiny}/>${service.price}
                              </Card.Header>
                            </Card>))
                            }
                            
                          </Accordion>
                        </Card.Body>
                      </Card>
                      </Col>
                    
                  </Animated>
                  </div>
                  </CardGroup>
                  </Row>
                
          </Animated>
        </div>
      </Container>
    );
  }
}

export default ToriDeets;