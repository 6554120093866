import React from 'react';
import {AnimatedOnScroll} from "react-animated-css-onscroll";
import './App.css';
import Clock from './Clock.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import FB from './FB-glyph2.png';
import FB2 from './FindUsOn_Header_2019.png';
import IG from './Glyph-Icon-IG2.png';
import IG2 from './IG-logo-black-and-white-transparent.png';
import CALL from './Call-Glyph2.png';
import EM from './Email-Glyph2.png';
import WEB from './WebsiteIcon2.png';
import BOOK from './BookingIcon2.png';
import {Animated} from "react-animated-css";
import GoogleMaps from './GoogleMaps.png';

class Contact extends React.Component {
  constructor() {
    super();
    this.state = {
      name: "Contact",
      HomeActive: false,
                        AboutUsActive: false,
                        ServicesActive: false,
                        ContactActive: true
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={2500} animationOutDuration={2500} isVisible={this.props.showHideAboutUs}>
 
      <Container>
        <Row className="Social-Icons">
          <h1 className="TitleFont2">Contact Info</h1>
        </Row>
        <Row className="Spacer"/>
        <Container fluid>
          <Row className="HoursCont">
            <Col xs={6} lg={2} className="SocialCont">
              <Image width={60} height={60} className="SocialIcons" src={IG} fluid/>
              <Button target="_blank" href="https://www.instagram.com/beautybylure/" variant="link" className="btn-secondary" >@beautybylure</Button>
            </Col>
            <Col xs={6} lg={2} className="SocialCont">
              <Image width={60} height={60} className="SocialIcons" src={FB} fluid/>
              <Button target= "_blank" href="https://www.facebook.com/beautybylure/" variant="link" className="btn-secondary">beautybylure</Button>
            </Col>
            <Col xs={6} lg={2} className="SocialCont">
              <Image width={60} height={60} className="SocialIcons" src={BOOK} fluid/>
              <Button target= "_blank" href="https://www.vagaro.com/lurebeautybar/book-now" variant="link" className="btn-secondary">Book With Victoria</Button>
            </Col>
            <Col xs={6} lg={2} className="SocialCont">
              <Image width={60} height={60} className="SocialIcons" src={EM} fluid/>
              <Button variant="link" href="mailto: contact@beautybylure.com" className="btn-secondary">contact@<n/>beautybylure.com</Button>
            </Col>
          </Row>
          <br/>
          <br/>
          
        </Container>
        <br/>
        <Container fluid className="Profiles">
          <Row className="LocationInfo">
            <Col xs={12} lg={true} className="SocialCont">

              <Row className="HoursNav" >
                <Button target="_blank" href="https://www.google.com/maps/place/Lure+Beauty+Bar/@43.3715407,-80.9791858,15z/data=!4m5!3m4!1s0x0:0x3737f0062e083506!8m2!3d43.3715407!4d-80.9791858" variant="link" className="btn-address" >Location</Button>
                </Row>
              <Row className="SocialCont">
                  <Button target="_blank" href="https://www.google.com/maps/place/Lure+Beauty+Bar/@43.3715407,-80.9791858,15z/data=!4m5!3m4!1s0x0:0x3737f0062e083506!8m2!3d43.3715407!4d-80.9791858" variant="outline-light" className="btn-secondary">18 Sparling Cres., Saint Marys, Ontario, Canada N4X 1A8</Button>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
      </Animated>
    );
  }
}

export default Contact;