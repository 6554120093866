import firebase from "firebase";

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyD-XguU_NOLR4qLzyXiRYX-lGOjWAjy3NY",
  authDomain: "lure-beauty-bar.firebaseapp.com",
  projectId: "lure-beauty-bar",
  storageBucket: "lure-beauty-bar.appspot.com",
  messagingSenderId: "215326466598",
  appId: "1:215326466598:web:6df7e0f8519e6be7ecbce7",
  measurementId: "G-6KPWEQMWM1"
});

const db = firebaseApp.firestore();


const auth = firebaseApp.auth();

const aut = firebaseApp.auth;


export { db , auth , aut };